<template>
	<v-layout column fill-height justify-start>
		<v-flex shrink>
			<v-layout justify-end row>
				<v-btn v-show="!userRoles.isGuest" color="primary" round @click="dialog = true">{{ $t('customer.addresses.actions.add') }}</v-btn>

				<w-dialog v-model="dialog" max-width="500" :title="$t('customer.addresses.actions.add')" @close="resetNewAddress()">
					<v-layout row wrap>
						<v-flex px-1 xs12>
							<v-select v-model="newAddress.name" :items="addressTypes" :label="$t('customer.addresses.type')" />
						</v-flex>
						<v-flex px-1 xs12>
							<v-text-field v-model="newAddress.address" color="primary" :label="$t('customer.addresses.street')" maxlength="191" />
						</v-flex>
						<v-flex px-1 xs12>
							<v-text-field v-model="newAddress.city" color="primary" :label="$t('city')" maxlength="191" />
						</v-flex>
						<v-flex px-1 xs12>
							<v-text-field v-model="newAddress.post_code" color="primary" :label="$t('post_code')" maxlength="5" />
						</v-flex>
						<v-flex px-1 xs12>
							<v-text-field v-model="newAddress.country" color="primary" :label="$t('country')" maxlength="191" />
						</v-flex>
					</v-layout>
					<template v-slot:actions>
						<v-layout align-center justify-end row>
							<v-btn color="primary" :disabled="!newAddress.name || !newAddress.address || !newAddress.city" flat round @click="addAddress"
								>{{ $t('actions.save') }}
							</v-btn>
						</v-layout></template
					>
				</w-dialog>
			</v-layout>
		</v-flex>
		<v-flex scroll-y>
			<v-layout mb-5 pb-4>
				<v-expansion-panel v-model="panel">
					<v-expansion-panel-content v-for="(address, index) in addresses" :key="address.id">
						<template v-slot:header>
							<v-layout align-center justify-space-between row>
								<v-flex :class="index === panel ? 'primary--text' : ''" shrink text-truncate v-text="address.name" />
								<v-flex v-if="index === panel" shrink>
									<v-btn
										v-show="!userRoles.isGuest"
										v-if="$vuetify.breakpoint.mdAndUp"
										color="primary"
										:disabled="!isUpdatedAddressValid"
										flat
										round
										@click.stop="updateAddress(address)"
									>
										<v-icon>save</v-icon>
										<v-flex ml-2>{{ $t('actions.save') }}</v-flex>
									</v-btn>
									<v-btn v-else v-show="!userRoles.isGuest" :disabled="!isUpdatedAddressValid" icon @click.stop="updateAddress(address)">
										<v-icon color="primary">save</v-icon>
									</v-btn>
									<v-btn v-show="!userRoles.isGuest" color="primary" flat icon @click.stop="deleteAddress(address)">
										<v-icon>delete</v-icon>
									</v-btn>
								</v-flex>
							</v-layout>
						</template>
						<v-card>
							<v-card-text>
								<v-layout row wrap>
									<v-flex px-1 xs12 sm4 lg3>
										<v-select v-model="address.name" :disabled="userRoles.isGuest" :items="addressTypes" :label="$t('customer.addresses.type')" />
									</v-flex>
									<v-flex px-1 xs12 sm8 lg9>
										<v-text-field
											v-model="address.address"
											:disabled="userRoles.isGuest"
											color="primary"
											:label="$t('customer.addresses.street')"
											maxlength="191"
										/>
									</v-flex>
									<v-flex px-1 xs12 sm6 md5>
										<v-text-field v-model="address.city" :disabled="userRoles.isGuest" color="primary" :label="$t('city')" maxlength="191" />
									</v-flex>
									<v-flex px-1 xs12 sm6 md2>
										<v-text-field v-model="address.post_code" :disabled="userRoles.isGuest" color="primary" :label="$t('post_code')" maxlength="5" />
									</v-flex>
									<v-flex px-1 xs12 sm6 md5>
										<v-text-field v-model="address.country" :disabled="userRoles.isGuest" color="primary" :label="$t('country')" maxlength="191" />
									</v-flex>
								</v-layout>
							</v-card-text>
						</v-card>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/VendorSettings/VendorsSettingsModuleGuard'

export default {
	name: 'CustomerDetailsAddresses',
	mixins: [CustomersManagerModuleGuard],
	props: {
		company: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			addresses: [],
			addressTypes: [
				this.$t('customer.addresses.home_office'),
				this.$t('customer.addresses.delivery_address'),
				this.$t('customer.addresses.billing_address'),
				this.$t('customer.addresses.shop'),
				this.$t('customer.addresses.other')
			],
			dialog: false,
			newAddress: {},
			panel: null
		}
	},
	computed: {
		...mapState({
			userRoles: state => state.company.userRoles
		}),
		isUpdatedAddressValid: function () {
			if (this.panel && this.addresses.length > 0) {
				const address = this.addresses[this.panel]
				if (!address.name || !address.name.trim()) {
					return false
				}
				if (!address.address || !address.address.trim()) {
					return false
				}
				if (!address.city || !address.city.trim()) {
					return false
				}
			}
			return true
		}
	},
	watch: {
		company: {
			deep: true,
			handler: function (newVal, oldVal) {
				if ((!oldVal || oldVal.id !== newVal.id) && newVal.id) {
					this.getAddresses()
					this.resetNewAddress()
				}
			},
			immediate: true
		}
	},
	methods: {
		addAddress: function () {
			this.service
				.addAddress(this.accountingFirmId, this.company.id, this.newAddress)
				.then(res => {
					this.addresses.push(res)
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('companies.messages.address_added'))
				})
				.then(() => {
					return this.resetNewAddress()
				})
				.finally(() => {
					this.dialog = false
				})
		},
		deleteAddress: function (address) {
			this.$dialog
				.warning({
					text: this.$t('customer.addresses.actions.are_you_sure', { name: address.address }),
					title: this.$t('customer.addresses.actions.delete'),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(res => {
					if (res) {
						this.service.deleteAddress(this.accountingFirmId, this.company.id, address.id).then(() => {
							this.panel = null
							this.addresses = this.addresses.filter(item => address.id != item.id)
							this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('companies.messages.address_deleted'))
						})
					}
				})
		},
		getAddresses: function () {
			this.service.getAddresses(this.accountingFirmId, this.company.id).then(res => {
				this.addresses = res
			})
		},
		/**
		 * @return {Promise<void>}
		 */
		resetNewAddress: function () {
			return new Promise(resolve => {
				this.newAddress = {}

				resolve()
			})
		},
		updateAddress: function (updatedAddress) {
			this.service.updateAddress(this.accountingFirmId, this.company.id, updatedAddress.id, updatedAddress).then(() => {
				this.addresses = this.addresses.map(address => (address.id == updatedAddress.id ? updatedAddress : address))
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('companies.messages.address_updated'))
			})
		}
	}
}
</script>
